<template>
  <div class="table-container">
    <VcaTable
      store="events/participations"
      :list="getList()"
      :colspan="9"
      :searchLabel="$t('events.participations.search')"
    >
      <template v-slot:colspan>
        <col width="1%" />
        <col width="14%" />
        <col width="14%" />
        <col width="14%" />
        <col width="14%" />
        <col width="14%" />
        <col width="14%" />
        <col width="1%" />
      </template>

      <template v-slot:desktop-filter>
        <vca-accordion :header="$t('events.participations.filter')">
          <div slot="body">
            <EventParticipationFilter v-model="filter" />
          </div>
        </vca-accordion>
      </template>

      <template v-if="selected.length > 0" v-slot:desktop-options>
        <div class="desktop-options">
          <div v-if="hasSystemPermission() || !eventClosed">
            <button
              @click="updateSelection('confirmed')"
              class="vca-button-small table-header-option"
            >
              {{ $t("button.accept") }}
            </button>
          </div>
          <div v-if="hasSystemPermission() || !eventClosed">
            <div class="vca-left">
              <vca-cancel-button
                @click="updateSelection('rejected')"
                :placeholder="$t('button.reject')"
              />
            </div>
          </div>
          <vue-excel-xlsx
            class="vca-button-small table-header-option"
            :data="exportData"
            :file-type="'xlsx'"
            :columns="exportExcel"
            :file-name="getExcelFileName"
          >
            {{ $t("button.export_excel") }}
          </vue-excel-xlsx>
        </div>
      </template>

      <template v-slot:desktop-header>
        <th class="vca-table-check">
          <label><vca-checkbox v-model="all_selected" /></label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('user.full_name')">
          <label>
            {{ $t("events.participations.supporter.name") }}
            &varr;
          </label>
        </th>
        <th class="vca-table-cell">
          <label>{{ $t("events.participations.comment") }}</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('crew_name')">
          <label>
            {{ $t("events.participations.crew.name") }}
            &varr;
          </label>
        </th>
        <th
          class="vca-table-cell sortable"
          @click="sort('user.profile.birthdate')"
        >
          <label>
            {{ $t("events.participations.supporter.age") }}
            &varr;
          </label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('gender_translation')">
          <label
            >{{ $t("events.participations.supporter.gender") }} &varr;</label
          >
        </th>
        <th class="vca-table-cell sortable" @click="sort('status_translation')">
          <label>{{ $t("events.participations.status.header") }} &varr;</label>
        </th>
        <th
          class="vca-table-cell sortable"
          @click="sort('user.profile.mattermost_username')"
        >
          <label>
            {{ $t("events.participations.mattermost_username.header") }}
            &varr;
          </label>
        </th>
        <th class="vca-table-cell">
          <label>{{ $t("table.options") }}</label>
        </th>
      </template>

      <template v-slot:desktop-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in getList()"
          :key="index"
        >
          <td class="vca-table-check">
            <label>
              <vca-checkbox v-model="selected" :id="res.id" />
            </label>
          </td>
          <td class="vca-table-cell">
            <label>
              {{ res.user.full_name }}
              <span v-if="res.user.display_name">
                ({{ res.user.display_name }})
              </span>
            </label>
          </td>
          <td class="vca-table-cell">
            <label>
              <div v-if="res.comment" class="inline-infobox">
                <vca-info-box>{{ res.comment }}</vca-info-box>
              </div>
              <div v-else>-</div>
            </label>
          </td>
          <td class="vca-table-cell">
            <label>{{ res.crew_name }}</label>
          </td>
          <td class="vca-table-cell">
            <label>
              {{ res.age }}
            </label>
          </td>
          <td class="vca-table-cell">
            <label> {{ res.gender_translation }} </label>
          </td>
          <td class="vca-table-cell">
            <label> {{ res.status_translation }} </label>
          </td>
          <td class="vca-table-cell">
            <label>
              {{ res.user.profile.mattermost_username }}
            </label>
          </td>
          <td class="vca-table-cell" @click="setCurrent(res)">
            <label
              ><img
                class="editable"
                src="@/assets/icons/edit.png"
                :title="$t('button.edit')"
                :alt="$t('button.edit')"
            /></label>
          </td>
        </tr>
      </template>

      <template v-slot:mobile-filter>
        <button class="vca-button" @click="do_filter = true">
          {{ $t("events.participations.filter") }}
        </button>
        <vca-popup
          v-if="do_filter"
          :show="do_filter"
          :title="$t('events.participations.filter')"
          @close="do_filter = false"
        >
          <EventParticipationFilter v-model="filter" />
        </vca-popup>
      </template>

      <template v-slot:mobile-header>
        <div><img width="20px" src="~@/assets/icons/sort.png" /></div>
        <div class="sortable" @click="sort('user.full_name')">
          <label>{{ $t("events.participations.supporter.name") }} &varr;</label>
        </div>
        <div class="sortable" @click="sort('user.email')">
          <label
            >{{ $t("events.participations.supporter.email") }} &varr;</label
          >
        </div>
      </template>

      <template v-slot:mobile-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in getList()"
          :key="index"
          @click="setCurrent(res)"
        >
          <td class="vca-table-cell">
            <vca-column>
              <vca-row>
                <div class="vca-left vca-table-index">
                  <span class="bold">
                    {{ res.user.full_name }} ({{ res.age }})
                  </span>
                </div>
                <div class="vca-right vca-table-index">
                  {{ res.status_translation }}
                </div>
              </vca-row>
            </vca-column>
          </td>
        </tr>
      </template>
    </VcaTable>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import VcaTable from "@/components/utils/VcaTable";
import EventParticipationFilter from "@/components/events/list/EventParticipationFilter";
export default {
  name: "EventParticipationTable",
  components: { VcaTable, EventParticipationFilter },
  data() {
    return {
      all_selected: false,
      selected: [],
      store: "events/participations/pg",
      initial: true,
      do_filter: false,
      currentFilter: {},
      currentTextFilter: "",
    };
  },
  watch: {
    pg: {
      handler(val) {
        this.$store.commit(this.store + "/pagination", val);
      },
      deep: true,
    },
    all_selected: function (nVal) {
      this.selected = nVal ? this.getList().map((val) => val.id) : [];
    },
  },
  props: {
    event: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    this.filter = {
      status: [],
    };
  },
  computed: {
    eventClosed() {
      return new Date() > new Date(this.event.end_at * 1000);
    },
    getFileName() {
      return (
        this.$t("events.list.export") +
        "_" +
        this.event.name.replace(" ", "_") +
        ".csv"
      );
    },
    getExcelFileName() {
      return (
        this.$t("events.list.export") + "_" + this.event.name.replace(" ", "_")
      );
    },

    filter: {
      set(value) {
        this.pg.filterObject = value;
      },
      get() {
        return this.pg.filterObject;
      },
    },
    current: {
      get() {
        return this.$store.state.events.participations.current;
      },
      set(value) {
        this.$store.commit("events/participations/current", value);
      },
    },
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
      list: "events/participations/list",
    }),
    emptyFilter() {
      return (
        (!this.pg.filter || this.pg.filter == "") &&
        this.filter.status.length == 0
      );
    },
    sameFilter() {
      return (
        Object.entries(this.currentFilter).toString() ===
          Object.entries(this.filter).toString() &&
        this.currentTextFilter == this.pg.filter
      );
    },
    exportData() {
      let list = this.getList().filter((el) => {
        return this.selected.includes(el.id);
      });
      if (list) {
        return list.map((el) => ({
          first_name: el.user.first_name,
          last_name: el.user.last_name,
          email: el.user.email,
          mattermost: el.user.profile.mattermost_username,
          phone: el.user.profile.phone,
          crew: this.clean_crew(el),
          age: el.age,
          comment: this.clean_comment(el.comment),
          status: el.status_translation,
        }));
      } else {
        return [];
      }
    },
    exportExcel() {
      return [
        {
          label: this.$t("events.participations.supporter.name"),
          field: "first_name",
        },
        {
          label: this.$t("events.participations.supporter.last_name"),
          field: "last_name",
        },
        {
          label: this.$t("events.participations.supporter.email"),
          field: "email",
        },
        {
          label: this.$t("events.participations.supporter.mattermost_username"),
          field: "mattermost",
        },
        {
          label: this.$t("events.participations.supporter.phone"),
          field: "phone",
        },
        {
          label: this.$t("events.participations.supporter.crew"),
          field: "crew",
        },
        {
          label: this.$t("events.participations.supporter.age"),
          field: "age",
        },
        {
          label: this.$t("events.participations.supporter.comment"),
          field: "comment",
        },
        {
          label: this.$t("events.participations.supporter.status"),
          field: "status",
        },
      ];
    },
  },
  methods: {
    clean_comment(value) {
      return value.replace(/\n/g, " ");
    },
    clean_crew(value) {
      if (value.crew_name === "-") {
        return "";
      } else {
        return (
          value.crew_name +
          " (" +
          this.$t("profile.active.current." + value.user.active.status) +
          ")"
        );
      }
    },
    updateSelection(state) {
      const participations = this.getList().filter((el) => {
        const included = this.selected.includes(el.id);
        return included && el.status != "withdrawn";
      });
      const withdrawn = this.getList().filter((el) => {
        const included = this.selected.includes(el.id);
        return included && el.status == "withdrawn";
      });

      let msg = "events.participations." + state + "_selected.confirm";

      if (participations.length == 0) {
        this.notification({
          title: this.$t("messages.title.warn"),
          body: this.$t("messages.participation.ignored_withdrawn"),
          type: "warn",
        });
        return;
      } else if (withdrawn.length > 0) {
        msg = "events.participations." + state + "_selected.confirm_ignore";
      }

      if (confirm(this.$t(msg))) {
        this.$store.commit("suppressMsg", true);
        Promise.all(
          participations.map(
            (el) => (
              (el.status = state),
              this.$store.dispatch({
                type: "events/participations/update",
                data: el,
              })
            )
          )
        )
          .then(() => {
            this.notification({
              title: "messages.title.success",
              body: this.$t("messages.participation.selection_" + state),
              type: "success",
            });
          })
          .catch(() => {
            this.notification({
              title: "messages.title.error",
              body: this.$t("messages.participation.not_" + state),
              type: "error",
            });
          })
          .finally(() => {
            this.current = null;
            this.$store.commit("suppressMsg", false);
          });
      }
    },
    getAge(birthdate) {
      return birthdate
        ? new Date(new Date() - new Date(birthdate * 1000)).getUTCFullYear() -
            1970
        : "-";
    },
    setCurrent(value) {
      if (this.current && this.current.id == value.id) {
        this.$store.commit("events/participations/current", null);
      } else {
        this.$store.commit("events/participations/current", value);
      }
    },
    getList() {
      // If the list is empty, we have an empty array
      if (!this.list) {
        return [];
      }

      // If the current page is out of scope, set it to last page
      if (this.pg.currentPage > this.pg.pageCount) {
        this.$store.commit(this.store + "/prevPage");
      }

      // Create a list for filtering and start filtering
      var filteredList = this.list;
      filteredList = filteredList.filter((row, index) => {
        // Add a rank to the current entry
        row.rank = index + 1;
        row.crew_name = row.crew.name ? row.crew.name : "-";
        row.age = this.getAge(row.user.profile.birthdate);
        row.gender_translation = this.$t(
          "users.gender." +
            (row.user.profile.gender != "" ? row.user.profile.gender : "none")
        );
        row.status_translation = this.$t(
          "events.participations.status." + row.status
        );

        // If the filter is empty, everything is fine
        if (this.emptyFilter) {
          return true;
        }
        if (this.filter.status.length > 0) {
          if (!this.filter.status.find((el) => row.status.includes(el))) {
            return false;
          }
        }

        // Filter for the current value of row.full_name etc.
        return (
          row.user.full_name
            .toLowerCase()
            .indexOf(this.pg.filter.toLowerCase()) > -1
        );
      });

      // Set the length of the list to the current filtered list
      this.pg.listLength = filteredList.length == 0 ? 1 : filteredList.length;
      if (
        this.pg.listLength < this.pg.pageSize ||
        this.pg.pageSize > filteredList.length
      ) {
        // If the length is smaller than the current page size, we adjust the pagesize to the length of the filtered list
        this.pg.pageSize = this.pg.listLength;
      } else if (!this.sameFilter || this.initial) {
        // If the filter has changed, we resize the list
        this.initial = false;
        this.pg.pageSize =
          this.pg.currentPageSize > this.pg.listLength
            ? this.pg.listLength
            : this.pg.currentPageSize;
      }
      // Set the new filters
      if (!this.sameFilter) {
        this.currentTextFilter = this.pg.filter;
        this.currentFilter = JSON.parse(JSON.stringify(this.filter));
      }

      // Now we filter the list to the current pageSize and paginate it
      return filteredList.filter((row, index) => {
        let start = (this.pg.currentPage - 1) * this.pg.pageSize;
        let end = this.pg.currentPage * this.pg.pageSize;
        if (index >= start && index < end) return true;
      });
    },
    sort(col) {
      // When sorting, we set the current page to 1
      this.$store.commit(this.store + "/firstPage");

      // If we have an empty list, return an empty array
      if (!this.list) {
        return [];
      }

      // Define the sorting direction, if current sort is the same as the column to sort, then switch direction
      if (this.pg.currentSort === col) {
        this.pg.currentSortDir =
          this.pg.currentSortDir === "asc" ? "desc" : "asc";
      } else {
        this.pg.currentSort = col;
      }

      // Sort the list depending on the column and the direction
      this.list.sort((a, b) => {
        var final_key = this.pg.currentSort;

        // Sort nested objects if sorting column contains a dot (.)
        if (col.indexOf(".") !== -1) {
          var column = col.split(".");
          var len = column.length;
          var i = 0;
          while (i < len - 1) {
            a = a[column[i]];
            b = b[column[i]];
            i++;
            final_key = column[i];
          }
        }

        // Set zero 0 at the end
        // if (parseInt(a[final_key]) == 0) return 1;
        // if (parseInt(b[final_key]) == 0) return -1;

        // Comapre integer values
        if (
          a[final_key] === parseInt(a[final_key], 10) ||
          a[final_key] === parseFloat(a[final_key], 10)
        ) {
          return this.pg.currentSortDir === "desc"
            ? a[final_key] - b[final_key]
            : b[final_key] - a[final_key];
        }

        // Compare string values
        let modifier = 1;
        if (this.pg.currentSortDir === "desc") modifier = -1;
        if (a[final_key].toLowerCase() < b[final_key].toLowerCase())
          return -1 * modifier;
        if (a[final_key].toLowerCase() > b[final_key].toLowerCase())
          return 1 * modifier;
        return 0;
      });
    },
  },
};
</script>
