<template>
  <div>
    <LoadingMessage v-if="loading" />
    <vca-card v-else>
      <h2>{{ $t("events.participations.header") }}</h2>
      <vca-card class="shadowed">
        <vca-column>
          <EventApplication :event="event" :showApplication="false" />
          <EventParticipationTable :event="event" />
        </vca-column>
        <vca-popup
          v-if="current != null"
          :show="current != null"
          :title="$t('events.popup.participation', { 0: current.name })"
          @close="current = null"
        >
          <EventParticipant />
        </vca-popup>
      </vca-card>
    </vca-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingMessage from "@/components/utils/LoadingMessage";
import EventApplication from "@/components/events/application/EventApplication";
import EventParticipationTable from "@/components/events/EventParticipationTable";
import EventParticipant from "@/components/events/participations/EventParticipant";
export default {
  name: "EventsParticipants",
  components: {
    LoadingMessage,
    EventApplication,
    EventParticipationTable,
    EventParticipant,
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.$store.commit("events/current", { id: this.$route.params.id });
    this.$store
      .dispatch("events/get")
      .then(() => {
        if (this.isEventAsp || this.canEdit) {
          this.$store
            .dispatch({
              type: "events/participations/listEvent",
              data: this.$route.params.id,
            })
            .then((response) => {
              this.$store.commit(
                "events/participations/list",
                !response || response.length == 0 ? [] : response
              );
              this.loading = false;
              const breadCrumb =
                '<a href="' +
                window.location.href +
                '" title="' +
                this.$t("events.participations.edit", {
                  0: "'" + this.event.name + "'",
                }) +
                '">' +
                this.$t("events.participations.edit", {
                  0: "'" + this.event.name + "'",
                }) +
                "</a>";
              this.$store.commit("breadCrumb", breadCrumb);
            });
        }
      })
      .catch((error) => {
        if (error.response.status == 404) {
          this.notification({
            title: this.$t("messages.title.error"),
            body: this.$t("messages.error.page_404"),
            type: "error",
          });
          this.$router.push({ path: "/events" });
        }
      });
  },
  computed: {
    ...mapGetters({
      event: "events/current",
      user: "user/current",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    canEdit() {
      return (
        this.hasSystemPermission() ||
        (this.hasPoolPermission(this.poolEventPermissions) &&
          this.user.crew &&
          this.event.crew &&
          this.user.crew.crew_id == this.event.crew.id)
      );
    },
    isEventAsp() {
      return (
        this.user &&
        this.event.event_asp_id &&
        this.user.id == this.event.event_asp_id
      );
    },
    current: {
      get() {
        return this.$store.state.events.participations.current;
      },
      set(value) {
        this.$store.commit("events/participations/current", value);
      },
    },
    participant: {
      get() {
        return this.$store.state.events.current;
      },
      set(value) {
        this.$store.commit("events/current", value);
      },
    },
  },
};
</script>
