<template>
  <vca-card>
    <h2>{{ current.user.full_name }}</h2>
    <vca-row>
      <div class="bold">{{ $t("events.crew.label") }}:</div>
      <span>
        {{ getCrew }}
        <span v-if="current.user.crew && current.user.crew.name != ''">
          ({{ $t("profile.active.current." + current.user.active.status) }})
        </span>
      </span>
      <div></div>
      <div></div>
    </vca-row>
    <vca-row>
      <vca-column>
        <vca-row>
          <div class="bold">
            {{ $t("events.participations.supporter.email") }}
          </div>
          <div>{{ current.user.email }}</div>
        </vca-row>
        <vca-row>
          <div class="bold">
            {{ $t("events.participations.supporter.phone") }}
          </div>
          <div>{{ current.user.profile.phone }}</div>
        </vca-row>
        <vca-row>
          <div class="bold">
            {{ $t("events.participations.supporter.mattermost_username") }}
          </div>
          <div>{{ current.user.profile.mattermost_username }}</div>
        </vca-row>
        <div></div>
      </vca-column>
      <vca-column>
        <vca-row>
          <div class="bold">
            {{ $t("events.participations.supporter.age") }}
          </div>
          <div>{{ getAge(current.user.profile.birthdate) }}</div>
        </vca-row>
        <vca-row>
          <div class="bold">
            {{ $t("events.participations.supporter.gender") }}
          </div>
          <div>
            {{ getGender }}
          </div>
        </vca-row>
        <vca-row>
          <div class="bold">
            {{ $t("events.participations.status.header") }}
          </div>
          <div>
            {{ $t("events.participations.status." + current.status) }}
          </div>
        </vca-row>
      </vca-column>
    </vca-row>
    <div class="bold">
      {{ $t("events.participations.comment") }}
    </div>
    <p>{{ current.comment }}</p>
    <div>
      <button
        v-if="hasSystemPermission() || (canAccept && !eventClosed)"
        @click="accept()"
        class="vca-button"
      >
        {{ $t("button.accept") }}
      </button>
      <div
        v-if="hasSystemPermission() || (canReject && !eventClosed)"
        class="vca-center"
      >
        <vca-cancel-button
          @click="reject()"
          :placeholder="$t('button.reject')"
        />
      </div>
      <vca-card v-if="current.status == 'withdrawn'" class="vca-center">
        <div class="bold highlight">
          {{ $t("events.participations.withdrawn") }}
        </div>
      </vca-card>
      <vca-card v-if="hasSystemPermission()" class="vca-center">
        <vca-cancel-button
          @click="doDelete()"
          :placeholder="$t('button.delete')"
        />
      </vca-card>
    </div>
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EventParticipant",
  computed: {
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    getCrew() {
      return this.current.user.crew && this.current.user.crew.name != ""
        ? this.current.user.crew.name
        : " - ";
    },
    getGender() {
      return this.current.user.profile.gender
        ? this.$t("users.gender." + this.current.user.profile.gender)
        : " - ";
    },
    canReject() {
      return ["requested", "confirmed"].includes(this.current.status);
    },
    canAccept() {
      return ["requested", "rejected"].includes(this.current.status);
    },
    eventClosed() {
      return new Date() > new Date(this.current.event.end_at * 1000);
    },
    current: {
      get() {
        return this.$store.state.events.participations.current;
      },
      set(value) {
        this.$store.commit("events/participations/current", value);
      },
    },
  },
  methods: {
    accept() {
      if (
        confirm(
          this.$t("events.participations.accept.confirm", {
            0: this.current.user.full_name,
          })
        )
      ) {
        this.$store
          .dispatch({ type: "events/participations/accept" })
          .then(() => {
            this.current = null;
          });
      }
    },
    reject() {
      if (
        confirm(
          this.$t("events.participations.reject.confirm", {
            0: this.current.user.full_name,
          })
        )
      ) {
        this.$store
          .dispatch({ type: "events/participations/reject" })
          .then(() => {
            this.current = null;
          });
      }
    },
    doDelete() {
      if (
        confirm(
          this.$t("events.participations.delete.confirm", {
            0: this.current.user.full_name,
          })
        )
      ) {
        this.$store
          .dispatch({
            type: "events/participations/delete",
            data: this.current.id,
          })
          .then(() => {
            this.current = null;
            this.$store
              .dispatch({
                type: "events/participations/listEvent",
                data: this.$route.params.id,
              })
              .then((response) => {
                this.$store.commit(
                  "events/participations/list",
                  !response || response.length == 0 ? [] : response
                );
              });
          });
      }
    },
    getAge(birthdate) {
      return birthdate
        ? new Date(new Date() - new Date(birthdate * 1000)).getUTCFullYear() -
            1970
        : "-";
    },
  },
};
</script>
