<template>
  <vca-column>
    <h3>{{ $t("events.participations.status.header") }}</h3>
    <vca-row>
      <vca-checkbox v-model="value.status" id="confirmed">{{
        $t("events.participations.status.confirmed")
      }}</vca-checkbox>
      <vca-checkbox v-model="value.status" id="requested">{{
        $t("events.participations.status.requested")
      }}</vca-checkbox>
      <vca-checkbox v-model="value.status" id="rejected">{{
        $t("events.participations.status.rejected")
      }}</vca-checkbox>
      <vca-checkbox v-model="value.status" id="withdrawn">{{
        $t("events.participations.status.withdrawn")
      }}</vca-checkbox>
    </vca-row>
  </vca-column>
</template>
<script>
export default {
  name: "EventParticipationFilter",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
};
</script>
